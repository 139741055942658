import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { VerifierType } from '@parashift/shared/types';

export interface VerifierParams {
  data_type: 'int' | 'float' | 'string';
  identifier: string;
  label: string;
}

const VerifierStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  dev: $localize `:@@common.dev:Dev` as 'dev',
  public: $localize `:@@common.public:Public` as 'public',
};
type VerifierStatus = (typeof VerifierStatus)[keyof typeof VerifierStatus];
export { VerifierStatus };

export interface VerifierPlainModel {
  id: string;
  created_at: string;
  identifier: string;
  parameters: VerifierParams[];
  status: VerifierStatus;
  title: string;
  updated_at: string;
}

@Model({ type: VerifierType })
export class Verifier extends BaseApiModel<VerifierPlainModel> {

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  identifier: string;

  @Attribute()
  parameters: VerifierParams[];

  @Attribute()
  status: VerifierStatus;

  @Attribute()
  title: string;

  @Attribute({ readonly: true })
  updated_at: string;
}
